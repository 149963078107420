.modal {
	position: fixed;
	z-index: 160;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;

	pointer-events: none;
	transition: all .3s .6s ease;
	visibility: hidden;
	opacity: 0;

	// States

	&.modal--active {
		pointer-events: auto;
		transition-delay: 0s;
		visibility: visible;
		opacity: 1;
	}
}

.modal-background {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;

	//background-color: rgba(0,0,0,0.8);
}

.modal-content {
	max-width: 100%;
	width: 500px;
	height: 590px;
	position: absolute;
	right: 0;
	top: 0;

	font-weight: 700;

	background-color: white;

	transition: all .3s .2s ease;
	opacity: 0;

	z-index: 1;


	@media @mobileQuery {
		height: auto;
	}

	// States

	.modal--active & {
		transition-delay: .4s;
		opacity: 1;
	}
}

.modal-content-inner {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: auto;
	padding: 2rem;
	//max-width: 610px;

	> * + * {
		margin-top: 25px;
	}
}

.modal-close {
	width: 40px;
	height: 40px;
	position: absolute;
	z-index: 9;
	right: 40px;
	top: 60px;

	border: 0;
	border-radius: 100%;

	background-color: white;

	cursor: pointer;
	transform: scale(0);
	transition: background-color .2s ease,
				transform .2s 0s cubic-bezier(0.6, -0.28, 0.735, 0.045);

	> * {
		max-width: 45%;
		position: absolute;
		left: 50%;
		top: 50%;

		transform: translate(-50%, -50%);

		color: #fff;
	}

	@media @mobileQuery {
		right: 10px;
		top: 10px;
	}

	// States

	&:focus {
		outline: 0;
	}

	.modal--active & {
		transform: scale(1);
		transition: background-color .2s ease,
					transform .2s .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}
}
