.signup {
	padding-bottom: 95px;
}

.signup-title {
	margin-top: 5rem;

	font-size: 18px;
	text-align: center;

	font-family: Tutima;

	@media @mobileQuery {
		margin-top: 7rem;
	}
}

.signup-form {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	> * {
		margin-top: 50px;
	}

	@media @mobileQuery {
		flex-direction: column;

		padding: 0 30px 70px 30px;
	}

	.input {
		position: relative;
		width: 45%;
		border-bottom: 1px solid #9B9B9B;

		@media @mobileQuery {
			width: 80%;
			margin: auto;
			margin-top: 50px;
		}

		input {
			width: 100%;
			height: 30px;
			text-align: center;
			border: none;
		}

		input:focus {
			outline: none;
		}

		input::placeholder {
			color: black;
			font-size: 18px;
			font-weight: 400;
			font-family: TutimaLight;

			@media @mobileQuery {
				font-size: 15px;
			}
		}
	}

	#email-error {
		position: absolute;
		width: 100%;
		top: 25px;
		left: 0px;

		color: #D0021B;
		font-size: 13px;
		font-weight: normal;
		text-align: center;

		@media @mobileQuery {
			top: 30px;
			left: 0px;
			right: 0px;
		}
	}

	#zipcode-error {
		position: absolute;
		width: 100%;
		top: 25px;
		left: 0px;

		color: #D0021B;
		font-size: 13px;
		font-weight: normal;
		text-align: center;

		@media @mobileQuery {
			top: 100px;
			left: 0px;
			right: 0px;
		}
	}
}

.signup-form-consent {
	position: relative;
	display: flex;

	> * + * {
		margin-left: 1rem;
	}

	.error {
		position: absolute;
		bottom: -25px;
		left: 25px;
		color: #D0021B;
		font-size: 13px;
		font-weight: normal;
		text-align: center;
	}

	.content {
		margin-top: 0;
		margin-bottom: 0;

		font-size: 13px;
		font-weight: normal;
	}
}

.signup-form-submit {
	width: 225px;
	height: 50px;
	border-radius: 0;
	border: 1px solid black;
	background-color: white;

	margin: 0 auto;
	margin-top: 50px;

	font-size: 15px;

	transition: all 0.3s ease;

	&:focus {
		outline: none;
	}

	&:hover {
		background-color: black;
		color: white;
	}
}

.checkbox {
	align-self: flex-start;

	input[type=checkbox] {
		display:none;
	}

	input[type=checkbox] + label{
		border: 1px solid black;
		height: 25px;
		width: 25px;
		display:inline-block;
		padding: 0;
		cursor: pointer;

		&.label-error {
			border: 1px solid #D0021B;
		}
	}

	input[type=checkbox]:checked + label {
		background: url(../svgs/input-checkbox.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 60%;
		height: 25px;
		width: 25px;
		display:inline-block;
	}
}

.response-title {
	font-size: 18px;
	padding: 50px 0;

	margin: 0;
	text-align: center;

	@media @mobileQuery {
		padding: 92px 0;
	}
}

.spinner {
	display: none;
	position: relative;
	width: 100%;
	height: 50px;
	text-align: center;

	transition: all .3s .6s ease;
	visibility: hidden;
	opacity: 0;

	// States

	&.spinner--active {
		display: block;
		pointer-events: auto;
		transition-delay: 0s;
		visibility: visible;
		opacity: 1;
	}
}

.spinner > div {
	position: relative;
	top: 50%;

	transform: translate(-0%, -50%);

	width: 18px;
	height: 18px;
	background-color: #333;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}
