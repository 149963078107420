/*	Oakless
 *	Some nice less mixins
 *	mainly stolen from https://github.com/clearleft/clearless
 *
 *	Settings for sprites:
 *	@sprite-image: '/example.png';
 *	@sprite-grid: 50px;
 *
 */



.font-face( @family-name, @font-path, @font-weight:normal, @font-style:normal ) {
	@font-face {
	    font-family: @family-name;
	    src: url('@{font-path}.eot');
	    src: url('@{font-path}.eot?#iefix') format('embedded-opentype'),
	         url('@{font-path}.woff') format('woff'),
	         url('@{font-path}.ttf') format('truetype');
	    font-weight: @font-weight;
	    font-style: @font-style;
	}
}

.wrap-words() {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.clear-apperance(){
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

.css-columns(@columns: 2, @gap: 60px, @rule: 1px solid #ddd){
	-moz-column-count:@columns;
	-webkit-column-count:@columns;
	column-count:@columns;

	-moz-column-gap:@gap;
	-webkit-column-gap:@gap;
	column-gap:@gap;

	-moz-column-rule: @rule;
	-webkit-column-rule: @rule;
	column-rule: @rule;
}

.ellipsis() {
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.border-radius(@radius:5px) {
    -webkit-border-radius: @arguments;
	-moz-border-radius: @arguments;
	border-radius: @arguments;
}

.box-sizing(@type: border-box) {
	-moz-box-sizing: @type;
	-webkit-box-sizing: @type;
	-ms-box-sizing: @type;
	box-sizing: @type;
}

.box-shadow(@shadow: 1px 1px 2px rgba(0,0,0,0.25)) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

.filter(@filter: grayscale(100%)) {
	-webkit-filter: @filter;
	-moz-filter: @filter;
	-ms-filter: @filter;
	-o-filter: @filter;
	filter: @filter;
}

.transition(@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	transition: @transition;
}

.rotate(@rotation) {
	-webkit-transform: rotate(@rotation);
	-moz-transform: rotate(@rotation);
	-o-transform: rotate(@rotation);
	transform: rotate(@rotation);
}

.placeholder(@color: #DDD) {
	:-moz-placeholder {
		color: @color;
	}
	::-webkit-input-placeholder {
		color: @color;
	}
}

#gradient {
	.horizontal (@start-color, @end-color, @bg-color) {
		background-color: @bg-color;
		background-repeat: repeat-x;
		background-image: -khtml-gradient(linear, left top, right top, from(@start-color), to(@end-color)); /* Konqueror */
		background-image: -moz-linear-gradient(left, @start-color, @end-color); /* FF 3.6+ */
		background-image: -ms-linear-gradient(left, @start-color, @end-color); /* IE10 */
		background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, @start-color), color-stop(100%, @end-color)); /* Safari 4+, Chrome 2+ */
		background-image: -webkit-linear-gradient(left, @start-color, @end-color); /* Safari 5.1+, Chrome 10+ */
		background-image: -o-linear-gradient(left, @start-color, @end-color); /* Opera 11.10 */
		background-image: -ms-linear-gradient(left, @start-color 0%, @end-color 100%);  /* IE10+ */
		background-image: linear-gradient(left, @start-color, @end-color); /* the standard */
	}
	.vertical (@start-color, @end-color, @bg-color)  {
		background-color: @bg-color;
		background-repeat: repeat-x;
		background-image: -khtml-gradient(linear, left top, left bottom, from(@start-color), to(@end-color)); /* Konqueror */
		background-image: -moz-linear-gradient(@start-color, @end-color); /* FF 3.6+ */
		background-image: -ms-linear-gradient(@start-color, @end-color); /* IE10 */
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @start-color), color-stop(100%, @end-color)); /* Safari 4+, Chrome 2+ */
		background-image: -webkit-linear-gradient(@start-color, @end-color); /* Safari 5.1+, Chrome 10+ */
		background-image: -o-linear-gradient(@start-color, @end-color); /* Opera 11.10 */
		background-image: -ms-linear-gradient(top, @start-color 0%, @end-color 100%);  /* IE10+ */
		background-image: linear-gradient(@start-color, @end-color); /* the standard */
	}
}

.arrow(@pos, @size, @bg-color) {
	width: 0;
	height: 0;
	border: @size solid transparent;
	._abbor_el( @pos, @bg-color );
}

.arrowbox(@pos, @size, @bg-color, @offset:50%) {

	@after-margin: @size * -1px;

	position: relative;
	background: @bg-color;

	&:after,
	&:before {
		._abpos(@pos);
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: transparent;
		._abbor_el(@pos, @bg-color);
		border-width: @size;
		._abpos_el(@pos, @size, @offset);
		._abmar_el(@pos, @after-margin);
	}
}

.arrowbox(@pos, @size, @bg-color, @border-width, @border-color, @offset:50%) {

	@before-margin: (@size + round(@border-width * 1.41421356)) * -1px;

	.arrowbox(@pos, @size, @bg-color, @offset);

	border: @border-width solid @border-color;

	&:before {
		border-color: transparent;
		._abbor_el(@pos, @border-color);
		border-width: @before-margin * -1;
		._abpos_el(@pos, @size, @offset);
		._abmar_el(@pos, @before-margin);
	}

}

._abpos( @pos ) when ( @pos = left ) { right: 100%; }
._abpos( @pos ) when ( @pos = right ) { left: 100%; }
._abpos( @pos ) when ( @pos = up ) { bottom: 100%; }
._abpos( @pos ) when ( @pos = down ) { top: 100%; }

._abpos_el( @pos, @size, @offset ) when ( @offset = 0 ) and ( @pos = left ) { top: @size; }
._abpos_el( @pos, @size, @offset ) when ( @offset = 0 ) and ( @pos = right ) { top: @size; }
._abpos_el( @pos, @size, @offset ) when ( @offset = 0 ) and ( @pos = up ) { left: @size; }
._abpos_el( @pos, @size, @offset ) when ( @offset = 0 ) and ( @pos = down ) { left: @size; }

._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and not ( ispixel(@offset) ) and ( @pos = left ) { top: @offset; }
._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and not ( ispixel(@offset) ) and ( @pos = right ) { top: @offset; }
._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and not ( ispixel(@offset) ) and ( @pos = up ) { left: @offset; }
._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and not ( ispixel(@offset) ) and ( @pos = down ) { left: @offset; }

._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and ( ispixel(@offset) ) and ( @pos = left ) { top: @offset + @size; }
._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and ( ispixel(@offset) ) and ( @pos = right ) { top: @offset + @size; }
._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and ( ispixel(@offset) ) and ( @pos = up ) { left: @offset + @size; }
._abpos_el( @pos, @size, @offset ) when not ( @offset = 0 ) and ( ispixel(@offset) ) and ( @pos = down ) { left: @offset + @size; }

._abbor_el( @pos, @color ) when ( @pos = left ) { border-right-color: @color; }
._abbor_el( @pos, @color ) when ( @pos = right ) { border-left-color: @color; }
._abbor_el( @pos, @color ) when ( @pos = up ) { border-bottom-color: @color; }
._abbor_el( @pos, @color ) when ( @pos = down ) { border-top-color: @color; }

._abmar_el( @pos, @margin ) when ( @pos = left ) { margin-top: @margin; }
._abmar_el( @pos, @margin ) when ( @pos = right ) { margin-top: @margin; }
._abmar_el( @pos, @margin ) when ( @pos = up ) { margin-left: @margin; }
._abmar_el( @pos, @margin ) when ( @pos = down ) { margin-left: @margin; }


.clearfix() {
	&:before,
	&:after {
	    content: "";
	    display: table;
	}
	&:after {
	    clear: both;
	}
	*zoom: 1;
}

.ir() {
	border: 0;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	background-color: transparent;
}

.hidden() {
	display: none;
	visibility: hidden;
}

.size(@thesize) {
	width: @thesize;
	height: @thesize;
}

.size(@width, @height) {
	width: @width;
	height: @height;
}

// System
.nudge-l( @pos ) when ( @pos = 0 ) {}
.nudge-l( @pos ) when not ( @pos = 0 ) { left: @pos; }
.nudge-r( @pos ) when ( @pos = 0 ) {}
.nudge-r( @pos ) when not ( @pos = 0 ) { right: @pos; }
.nudge-t( @pos ) when ( @pos = 0 ) {}
.nudge-t( @pos ) when not ( @pos = 0 ) { top: @pos; }
.nudge-b( @pos ) when ( @pos = 0 ) {}
.nudge-b( @pos ) when not ( @pos = 0 ) { bottom: @pos; }


// ==============================================
// Sprite helpers
// ==============================================

.sprite(@x, @y, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) {
	.sprite-image(@sprite-image);
	.sprite-pos(@x, @y, @sprite-grid);
}

.sprite-sized(@x, @y, @size, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) when not (isnumber(@sprite-image)) {
	.sprite(@x, @y, @sprite-image, @sprite-grid);
	.size(@size);
}

.sprite-sized(@x, @y, @width, @height, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) when (isnumber(@height)) {
	.sprite(@x, @y, @sprite-image, @sprite-grid);
	.size(@width,@height);
}

.sprite-ir(@x, @y, @size, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) when not (isnumber(@sprite-image)) {
	.ir();
	.sprite(@x, @y, @sprite-image, @sprite-grid);
	.size(@size);
}

.sprite-ir(@x, @y, @width, @height, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) when (isnumber(@height)) {
	.ir();
	.sprite(@x, @y, @sprite-image, @sprite-grid);
	.size(@width,@height);
}

.sprite-image(@sprite-image:@sprite-image) {
	background-image: url("@{sprite-image}.png");
	background-repeat: no-repeat;
	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		background-image: url("@{sprite-image}2x.png");
		background-size: @sprite-size;
	}
}

.sprite-pos(@x, @y, @sprite-grid:@sprite-grid) {
	background-position: -((@x*@sprite-grid*1px))*1px  -((@y*@sprite-grid*1px))*1px;
}

.sprite-pos-sized(@x, @y, @size, @sprite-grid:@sprite-grid) when not (isnumber(@sprite-image)) {
	.sprite-pos(@x, @y, @sprite-grid);
	.size(@size);
}

.sprite-pos-sized(@x, @y, @width, @height, @sprite-grid:@sprite-grid) when (isnumber(@height)) {
	.sprite-pos(@x, @y, @sprite-grid);
	.size(@width,@height);
}


.prepend-icon( @icon-image, @width, @height, @nudge-left:0, @nudge-top:0, @pad:10px ) {
	position: relative;
	padding-left: @width + @pad;
	&:before {
		._generated-icon( @width, @height, @icon-image );
		top: @nudge-top;
		left: @nudge-left;
	}
}

.append-icon( @icon-image, @width, @height, @nudge-right:0, @nudge-top:0, @pad:10px ) {
	position: relative;
	padding-right: @width + @pad;
	.generatedcontent &:after {
		._generated-icon( @width, @height, @icon-image );
		top: @nudge-top;
		right: @nudge-right;
	}
}

// Sprited icons
.prepend-sprite-icon(@x, @y, @width, @height, @nudge-left:0, @nudge-top:0, @pad:10px, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) {
	position: relative;
	padding-left: @width + @pad;
	&:before {
		._generated-sprite-icon( @x, @y, @width, @height, @sprite-image, @sprite-grid );
		top: @nudge-top;
		left: @nudge-left;
	}
}

.append-sprite-icon(@x, @y, @width, @height, @nudge-right:0, @nudge-top:0, @pad:10px, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) {
	position: relative;
	padding-right: @width + @pad;
	&:after {
		._generated-sprite-icon( @x, @y, @width, @height, @sprite-image, @sprite-grid );
		top: @nudge-top;
		right: @nudge-right;
	}
}

.prepend-sprite-icon-pos(@x, @y, @nudge-left:0, @nudge-top:0, @sprite-grid:@sprite-grid) {
	&:before {
		.sprite-pos(@x, @y, @sprite-grid);
		.nudge-l(@nudge-left);
		.nudge-t(@nudge-top);
	}
}

.append-sprite-icon-pos(@x, @y, @nudge-right:0, @nudge-top:0, @sprite-grid:@sprite-grid) {
	&:after {
		.sprite-pos(@x, @y, @sprite-grid);
		.nudge-r(@nudge-right);
		.nudge-t(@nudge-top);
	}
}

.prepend-icon-setup(@width:0, @height:0, @nudge-left:0, @nudge-top:0, @pad:10px) {
	position: relative;
	._pad-left(@width, @pad);
	&:before {
		position: absolute;
		display: block;
		content: ' ';
		top: 0;
		left: 0;
		._size(@width, @height);
	}
}

.append-icon-setup(@width:0, @height:0, @nudge-left:0, @nudge-top:0, @pad:10px) {
	position: relative;
	._pad-right(@width, @pad);
	&:after {
		position: absolute;
		display: block;
		content: ' ';
		top: 0;
		right: 0;
		._size(@width, @height);
	}
}

.prepend-icon-image(@icon-image, @width:0, @height:0, @nudge-left:0, @nudge-top:0, @pad:10px) {
	._pad-left(@width, @pad);
	&:before {
		background: url(@icon-image) no-repeat 0 0;
		._size(@width, @height);
		.nudge-l(@nudge-left);
		.nudge-t(@nudge-top);
	}
}

.append-icon-image(@icon-image, @width:0, @height:0, @nudge-right:0, @nudge-top:0, @pad:10px) {
	._pad-right(@width, @pad);
	&:after {
		background: url(@icon-image) no-repeat 0 0;
		._size(@width, @height);
		.nudge-r(@nudge-right);
		.nudge-t(@nudge-top);
	}
}


// ---- internal use mixins -----------------------
._generated-icon(@width, @height, @icon-image) {
	position: absolute;
	display: block;
	content: ' ';
	background: url(@icon-image) no-repeat 0 0;
	.size(@width, @height);
}

._generated-sprite-icon(@x, @y, @width, @height, @sprite-image:@sprite-image, @sprite-grid:@sprite-grid) {
	position: absolute;
	display: block;
	content: ' ';
	.sprite-sized(@x, @y, @width, @height, @sprite-image, @sprite-grid);
}

._pad-left(@width, @pad) when (@width = 0) {}
._pad-left(@width, @pad) when not (@width = 0) { padding-left: @width + @pad; }
._pad-right(@width, @pad) when (@width = 0) {}
._pad-right(@width, @pad) when not (@width = 0) { padding-right: @width + @pad; }

._gc-pad-left(@width, @pad) when (@width = 0) {}
._gc-pad-right(@width, @pad) when (@width = 0) {}
._gc-pad-left(@width, @pad) when not (@width = 0) {
	._pad-left(@width, @pad);
}
._gc-pad-right(@width, @pad) when not (@width = 0) {
	._pad-right(@width, @pad);
}

._size(@width, @height) when (@width = 0) and (@height = 0) {}
._size(@width, @height) when (@width = 0) and not (@height = 0) {
	height: @height;
}
._size(@width, @height) when not (@width = 0) and (@height = 0) {
	width: @width;
}
._size(@width, @height) when not (@width = 0) and not (@height = 0) {
	width: @width;
	height: @height;
}



.normalize() {

	/* normalize.css 2012-03-11T12:53 UTC - http://github.com/necolas/normalize.css */

	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	nav,
	section,
	summary {
	    display: block;
	}
	audio,
	canvas,
	video {
	    display: inline-block;
	    *display: inline;
	    *zoom: 1;
	}
	audio:not([controls]) {
	    display: none;
	    height: 0;
	}
	[hidden] {
	    display: none;
	}
	html {
	    font-size: 100%;
	    -webkit-text-size-adjust: 100%;
	    -ms-text-size-adjust: 100%;
	}
	html,
	button,
	input,
	select,
	textarea {
	    font-family: sans-serif;
	}
	body {
	    margin: 0;
	}
	a:focus {
	    outline: thin dotted;
	}
	a:hover,
	a:active {
	    outline: 0;
	}
	h1 {
	    font-size: 2em;
	    margin: 0.67em 0;
	}
	h2 {
	    font-size: 1.5em;
	    margin: 0.83em 0;
	}
	h3 {
	    font-size: 1.17em;
	    margin: 1em 0;
	}
	h4 {
	    font-size: 1em;
	    margin: 1.33em 0;
	}
	h5 {
	    font-size: 0.83em;
	    margin: 1.67em 0;
	}
	h6 {
	    font-size: 0.75em;
	    margin: 2.33em 0;
	}
	abbr[title] {
	    border-bottom: 1px dotted;
	}
	b,
	strong {
	    font-weight: bold;
	}
	blockquote {
	    margin: 1em 40px;
	}
	dfn {
	    font-style: italic;
	}
	mark {
	    background: #ff0;
	    color: #000;
	}
	p,
	pre {
	    margin: 1em 0;
	}
	pre,
	code,
	kbd,
	samp {
	    font-family: monospace, serif;
	    _font-family: 'courier new', monospace;
	    font-size: 1em;
	}
	pre {
	    white-space: pre;
	    white-space: pre-wrap;
	    word-wrap: break-word;
	}
	q {
	    quotes: none;
	}
	q:before,
	q:after {
	    content: '';
	    content: none;
	}
	small {
	    font-size: 75%;
	}
	sub,
	sup {
	    font-size: 75%;
	    line-height: 0;
	    position: relative;
	    vertical-align: baseline;
	}
	sup {
	    top: -0.5em;
	}
	sub {
	    bottom: -0.25em;
	}
	dl,
	menu,
	ol,
	ul {
	    margin: 1em 0;
	}
	dd {
	    margin: 0 0 0 40px;
	}
	menu,
	ol,
	ul {
	    padding: 0 0 0 40px;
	}
	nav ul,
	nav ol {
	    list-style: none;
	    list-style-image: none;
	}
	img {
	    border: 0;
	    -ms-interpolation-mode: bicubic;
	}
	svg:not(:root) {
	    overflow: hidden;
	}
	figure {
	    margin: 0;
	}
	form {
	    margin: 0;
	}
	fieldset {
	    border: 1px solid #c0c0c0;
	    margin: 0 2px;
	    padding: 0.35em 0.625em 0.75em;
	}
	legend {
	    border: 0;
	    padding: 0;
	    white-space: normal;
	    *margin-left: -7px;
	}
	button,
	input,
	select,
	textarea {
	    font-size: 100%;
	    margin: 0;
	    vertical-align: baseline;
	    *vertical-align: middle;
	}
	button,
	input {
	    line-height: normal;
	}
	button,
	input[type="button"],
	input[type="reset"],
	input[type="submit"] {
	    cursor: pointer;
	    -webkit-appearance: button;
	    *overflow: visible;
	}
	button[disabled],
	input[disabled] {
	    cursor: default;
	}
	input[type="checkbox"],
	input[type="radio"] {
	    box-sizing: border-box;
	    padding: 0;
	    *height: 13px;
	    *width: 13px;
	}
	input[type="search"] {
	    -webkit-appearance: textfield;
	    -moz-box-sizing: content-box;
	    -webkit-box-sizing: content-box;
	    box-sizing: content-box;
	}
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button {
	    -webkit-appearance: none;
	}
	button::-moz-focus-inner,
	input::-moz-focus-inner {
	    border: 0;
	    padding: 0;
	}
	textarea {
	    overflow: auto;
	    vertical-align: top;
	}
	table {
	    border-collapse: collapse;
	    border-spacing: 0;
	}
}

.retailer-form {

	.form-title {
		font-size: 3rem;
	}
}

.page-template-default,
.page-template-plain-php,
.page-template-tutimaplain-php,
.page-template-plain,
.page-template-tutima,
.error404,
.retailer {
	.site-header {
		background: #000;
	}

	.main-content {
		background: #FFF;
		padding: 60px 0;
	}
}
