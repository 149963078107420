/*
Setup
 */

@import "oak";
@import "lity";
@import "modal";
@import "newsletter-signup";


.normalize();

@animation-speed    : .4s;
@mobileBreakpoint   : 640px;
@smallDesktopBreakpoint   : 1000px;

@sprite-image       : '../../../img/sprite'; /* ommit extension, assume .png! - eg. sprite.png & sprite2x.png */
@sprite-size        : 200px 800px; /* we use it to resize the retina version */
@sprite-grid        : 50px;

@grey-border        : solid 1px #ccc;
@grey-border-dotted : dotted 1px #ccc;

// media queries
@desktopBreakpoint  : (@mobileBreakpoint + 1);
@mobileQuery        : ~"(max-width: @{mobileBreakpoint})";
@smallDesktopQuery  : ~"(max-width: @{smallDesktopBreakpoint})";
@desktopQuery       : ~"(min-width: @{desktopBreakpoint})";

/* Variables and mixins */
.tag-element(@width: 14px) {
	display: block;
	content: '';
	width: @width;
	height: @width * 2;
	position: absolute;
	left: 50%;
	top: 0;
	margin-left: -(@width / 2);
}

.tag(@color: #000000, @width: 14px){
	&:before {
		.tag-element(@width);
		background: @color;
	}
}

/*
Global
 */

*, *:before, *:after {
	.box-sizing(border-box);
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

html, body {
	height: 100%;
}

body {
	background: #fff;
	overflow-y: scroll;

	&.has-featured-activity {
		min-height: 700px;
		@media @mobileQuery {
			min-height: 550px;
		}
	}

	&.has-featured-activity.background-video-open {
		min-height: 926px;
		@media @mobileQuery {
			min-height: 630px;
		}
	}

	&.no-scroll {
		overflow: hidden;
	}
}

ul, li {
	list-style: none;
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}


.site-wrapper {
	position: relative;
	min-height: 100%;

	@media @mobileQuery {
		padding-bottom: 100px;

		.section-open & { padding-bottom: 0; }
	}
	@media @desktopQuery {
		border: 10px solid #fff;
	}
}

.bg-video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	transform: translate(-50%, -50%);
}

.center {
	max-width: 960px;
	margin: 0 auto;

	&.plain {

		.main-content& {
			background: #FFF;
			padding: 60px 0;
		}
	}
}


/* Typography */
.font-face( 'TutimaThin', '../fonts/tutima-thin', 'normal');
.font-face( 'TutimaLight', '../fonts/tutima-light', 'normal');
.font-face( 'Tutima', '../fonts/tutima-regular', 'normal');

.font-root(@size: 62.5%) {
	font-family: Arial, "Lucida Grande", sans-serif;
	font-weight: 300;
	font-size: 62.5%;
	color: #303030;
	line-height: 1.2;
}
.font-tutima(@size: 14px) {
	font-family: Tutima;
	font-size: @size;
}

.font-upper(@size: 11px, @spacing: 1px) {
	.font-tutima(@size);
	text-transform: uppercase;
	letter-spacing: @spacing;
	word-spacing: .25em;
}

html {
	.font-root();
}

h1, h2, h3, h4, h5, h6, .subtitle {
	margin: 0 0 .15em;
	font-family: TutimaLight;
	letter-spacing: -0.03em;
	font-weight: normal;
	line-height: 1;
	display: block;
}
h1 { font-size: 6rem; }
h2 { font-size: 4rem; }
h3 { font-size: 3rem; }
h4 { font-size: 2.4rem; }
h5 { font-size: 2rem; }
h6 { font-size: 1.4rem; text-transform: uppercase; }

a {
	color: inherit;
	text-decoration: none;

	&:hover { text-decoration: underline; }
}

p {
	font-size: 1.4rem;
	line-height: 1.5;
	color: rgb(82, 82, 82);
}

.subtitle {
	font-size: 4rem;
}

.preamble {
	max-width: 680px;
	margin: 0 auto;
	text-align: center;

	p {
		.font-tutima(1.8rem);
		line-height: 1.4;
	}
}

.date{
	.font-tutima(11px);
	letter-spacing: 1px;
	text-transform: uppercase;
}

.wp-caption-text{
	font-size: 1.3rem;
	margin-top: 0.5em;
}

.load-more{
	text-align: center;
	display: block;
	margin: 6rem 0 4rem;
	.button{
		text-decoration: none;
		letter-spacing: 1px;
	}
	.loading{
		background: url('../../../img/loading-light.gif') no-repeat;
		width: 16px;
		height: 16px;
		margin: 4px auto;
	}
}

// Some mobile type adjustments
@media @mobileQuery {
	h1 { font-size: 4rem; }
	h2, .subtitle { font-size: 2rem; }
	h3 { font-size: 2rem; }
	h4 { font-size: 1.8rem; }
	h5 { font-size: 1.5rem; }
	.preamble p { font-size: 1.7rem; }
}

// forms
[type=text],
[type=password],
[type=email] {
	background: #fff;
	border: 1px solid #bfbdbd;
	padding: 4px 6px 3px;
	font-size: 12px;
	max-width: 100%;
	vertical-align: middle;

}


/* Reusables */
.button {
	display: inline-block;
	padding: 3px 7px 2px;
	background: #fff;
	border: @grey-border;
	.font-upper(11px);

	&:hover {
		background: #f0f0f0;
		text-decoration: none;
	}

	&.link {
		padding: 5px 9px 4px;
		.append-sprite-icon(2, 2, 13px, 9px, 4px, 7px);
	}

	&.transparent {
		padding: 9px 14px 8px;
		background-color: transparent;
	}

	&.unhover {
		&:hover {
			background: inherit;
		}
	}
}

.overlap-title, .tab-menu {
	display: table;
	position: relative;
	margin: -11px auto;
	padding: 4px 11px;
	background: #fff;
	.font-upper(11px);
	text-align: center;
	white-space: nowrap;
	border: @grey-border;
	z-index: 2;
}

.tab-menu {
	padding: 0;

	li {
		display: inline-block;
		padding: 4px 11px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		& + li {
			border-left: @grey-border-dotted;
		}

		&.current, &:hover {
			background: #f1f1f1;
		}
	}
}

.custom-select {
	display: inline-block;
	position: relative;
	overflow: hidden;
	background: #fff;
	border: 1px solid #bfbdbd;
	padding-right: 15px;
	font-size: 13px;
	max-width: 100%;
	vertical-align: middle;

	div {
		padding: 4px 6px 3px;
		.font-tutima(1.1rem);
		color: #3a3a3a;
		font-weight: bold;
		text-align: left;
		text-transform: uppercase;
		.ellipsis();
	}

	select {
		position: absolute;
		top: -3px;
		left: 0;
		.clear-apperance();
		opacity: 0;
		width: 110%;
		height: 120%;
		cursor: pointer;
		font-size: 1.4rem;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}

	&:before {
		display: block;
		position: absolute;
		right: 6px;
		top: 8px;
		content: '';
		.size(0);
		border-style: solid;
		border-width: 6px 4px;
		border-color: #3a3a3a transparent transparent transparent;
	}
}


// icons
.email-icon { .prepend-sprite-icon(0, 2, 14px, 11px, 2px, 1px); }
.phone-icon { .prepend-sprite-icon(1, 2, 14px, 11px, 2px, 2px); }


/* Background fader */
.background-fader {
	position: fixed;
	z-index: -1;
	top: 0;
	right: 0;
	left: 0;
	height: 100%;
	background-position: center;
	background-size: cover;

	div {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 100%;
		background-position: center;
		background-size: cover;
		.transition(opacity @animation-speed linear);
		opacity: 0;
	}
}


/* Meta menu (top and footer) */
.meta-menu {
	background: #fff;
	height: 0;
	overflow: hidden;
	.transition(height @animation-speed / 2);

	.center {
		padding-top: 20px;
		overflow: hidden;
	}

	.logo {
		float: left;
		.sprite-ir(0, 0, 91px, 80px);
	}

	.contact {
		.font-upper(11px, 0);
	}

	.menu-group {
		.font-tutima(14px);
	}


	@media @mobileQuery {
		.center { border-bottom: 1px solid #d6d6d6; }
		.logo { margin-left: 20px; }
		.contact { float: right; margin: 50px 20px 20px; }
		.menu-group {
			clear: both;
			text-align: center;

			a { display: block; }

			h6 {
				cursor: pointer;
				margin: 0;
			}

			h6, li {
				border-top: 1px solid #d6d6d6;
				padding: 10px;
			}

			ul {
				display: none;
				background: #f2f2f3;
			}

			&.open ul { display: block; }
		}

		// one column (open)
		&.top-menu.columns-1 .menu-group ul { display: block; }

	}
	@media @desktopQuery {
		.center {
			padding-bottom: 20px;
		}

		.logo-contact {
			float: left;
			width: 40%;
		}

		.contact {
			float: left;
			margin: 50px 0 20px 40px;
		}

		.menu-group {
			float: left;
			width: 20%;
			padding-left: 3%;

			h6 {
				border-bottom: 1px solid #d6d6d6;
				margin-bottom: 10px;
				padding-bottom: 5px;
			}

		}

		&.columns-1 .menu-group { width: 25%; }

		// two columns
		&.top-menu.columns-2 .menu-group:nth-child(2) { margin-left: 20%; }

		// one column
		&.top-menu.columns-1 .menu-group:nth-child(2) { margin-left: 35%; }

	}
}


/* Header */
.site-header {
	position: relative;
	color: #fff;
	text-align: center;
	overflow: hidden;
	padding-bottom: 20px;

	.logo {
		display: block;
		width: 180px;
		margin: 0 auto 20px;
		padding-top: 20px;
		outline: 0;
		.transition(all @animation-speed @animation-speed / 2);

		img { width: 100%; }
	}

	.header-content {
		.transition(height @animation-speed);
	}

	.tagline {
		display: inline-block;
		max-width: 580px;
		opacity: 1;
		font-family: TutimaThin;
		font-size: 4.4rem;
		.transition(opacity @animation-speed / 2);
	}

	.langship-menu {
		display: none;
		background: #FFF;
		color: #222;
		text-align: left;

		.title {
			border-bottom: 1px solid #d6d6d6;
			margin-bottom: 10px;
			padding-bottom: 5px;
		}

		& > div {
			padding: 20px;
		}

		p {
			.font-tutima();
			line-height: 1.3;
		}

		.select {
			h6 {
				font-size: 11px;
				margin-bottom: 5px;
			}

			.custom-select {
				width: 100%;
			}
		}

		.close-x {
			display: none;
		}
	}
	.langship-toggle a:before {
		content: attr(data-current-short);
	}

	.header-menu .initiated-purchase {
		display: block;
		background: #000;
		color: #fff;
		text-transform: uppercase;
		font-size: 10px;
	}

	@media @mobileQuery {
		overflow: hidden;

		.header-menu {
			position: relative;
			.clearfix();

			& > * {
				background: #fff;
				color: #000;
				.font-tutima(16px);
				padding: 7px;
			}
			.home-link { display: none; }

			.menu-toggle,
			.find-retailers-top-link,
			.langship-toggle, .facebook-top-link{
				font-size: 1.4rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.menu-toggle {
				display: block;
				float: left;
				width: 30%;
				border-right: 1px solid #d6d6d6;
			}

			.find-retailers-top-link{
				display: block;
				float: left;
				width: 25%;
				border-right: 1px solid #d6d6d6;
			}

			.langship-toggle, .facebook-top-link {
				width: 22.5%;
				float: right;
			}

			.facebook-top-link {
				border-right: 1px solid #d6d6d6;
			}

			.initiated-purchase {
				clear: both;
				border-bottom: 1px solid #222;
			}
		}

		.langship-menu {
			position: relative;

			.select:first-of-type {
				margin-bottom: 10px;
			}

			&:after {
				display: block;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 50%;
				border-bottom: 1px solid #D6D6D6;
			}
		}

		.logo { width: 130px; margin-bottom: 10px; }

		.section-open & .header-content {
			height: 0;
		}

		.tagline {
			line-height: 1;
			margin-bottom: 25px;
			font-size: 3.5rem;
		}
	}

	@media @desktopQuery {
		.transition(height @animation-speed @animation-speed / 2);
		height: 350px;

		.langship-menu {
			position: absolute;
			top: 8px;
			right: 148px;
			width: 420px;
			text-align: left;
			z-index: 15;
			box-shadow: inset 0 0 0 1px #eee;

			.close-x {
				display: block;
				position: absolute;
				top: 5px;
				right: 5px;
				border: 4px solid #000;
				background-color: #000;
				.sprite-sized(0, 4, 18px, 18px);
			}

			.select {
				display: inline-block;
				width: 46%;

				&:first-of-type { margin-right: 5%; }
			}
		}

		.header-menu {
			position: absolute;
			top: 10px;
			left: 10px;
			right: 10px;
			.font-tutima(14px);
			text-align: right;

			& > * {
				display: block;
			}
			.find-retailers-top-link{
				display: block;
				min-height: 19px;
				padding-top: 3px;
			}
			.langship-toggle, .facebook-top-link, .menu-toggle, .find-retailers-top-link {
				float: right;
			}
			.home-link, .initiated-purchase {
				float: left;
				margin: 3px;
			}

			.menu-toggle, .find-retailers-top-link {
				padding-left: 10px;
				margin-left: 10px;
				border-left: 1px solid rgba(255, 255, 255, .6);
			}

			.facebook-top-link {
				padding-right: 10px;
				margin-right: 10px;
				border-right: 1px solid rgba(255, 255, 255, .6);
			}

			.initiated-purchase {
				display: inline-block;
				background: #FFF;
				margin: 0 0 0 10px;
				padding: 5px 7px;
				color: #222;
			}

			.header-content {
				max-height: 100%;
				overflow: hidden;
			}

			.langship-toggle, .facebook-top-link {
				margin-top: 3px;

				a {
					text-decoration: underline;

					&:before {
						content: attr(data-current-full);
					}
				}
			}

			.menu-toggle i {
				display: inline-block;
				position: relative;
				top: 3px;
				margin-left: 6px;
				.sprite-sized(1, 3, 15px);
				-webkit-transition: -webkit-transform @animation-speed * 1.5;
				-moz-transition: -webkit-transform @animation-speed * 1.5;
				transition: -webkit-transform @animation-speed * 1.5;
			}

			.menu-toggle.open i {
				.rotate(180deg);
			}
		}

		.logo { padding-top: 70px; }

		.section-open & {
			height: 130px;

			.logo {
				padding-top: 20px;
				width: 100px;
			}

			.tagline { opacity: 0; }
			.tag { top: -28px; }
		}

		.background-video-open & {
			height: 580px;

			.logo {
				padding-top: 20px;
				width: 100px;
			}

			.tagline {
				margin-top: 120px;
			}

			.tag { top: -28px; }
		}

		.tag {
			.tag-element();
			background: #fff;
			.transition(top @animation-speed @animation-speed / 2);
		}
	}
}

// language dropdown
.language-list {
	display: inline-block;
	position: relative;
	padding-right: 12px;

	&:after {
		display: block;
		position: absolute;
		right: -2px;
		top: 6px;
		content: '';
		border: 4px solid transparent;
		border-top-color: #fff;
	}

	&.open:after {
		top: 1px;
		border-color: transparent;
		border-bottom-color: #fff;
	}

	ul {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
	}

	&.open ul {
		display: block;
		padding: 20px 11px;
	}
}

/* owc_content */
.owc_content{
	margin-top: 5rem;

	> * {
		margin-top: 0;
	}

	h2 {
		margin-top: 6px; // fix to give room for ümlåüts
	}
}
.owc_content{
	&.blocks{
		.block{
			position: relative;
		}
	}
}

.video-player {
	position: relative;
    cursor: pointer;

    &:hover {
    	.video-playBtn {
	        &:after {
	            border-left: 22px solid #ccc;
	        }
    	}
    }
}

.video-playBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(100, 100, 100, .5);

    &:after {
        position: absolute;
        top: 50%;
        left: 56%;
        transform: translate(-50%, -50%);
        content: '';
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 22px solid #fff;
    }
}

@media @desktopQuery {
	.owc_content{
		&.columns{
			.css-columns(2);
		}

		&.blocks{
			overflow: hidden;
			.block{
				width: 50%;
				position: relative;
				&:first-child{
					float: left;
					padding-right: 30px;
					border-right: 1px solid #ddd;
				}
				&:last-child{
					float: right;
					padding-left: 30px;
				}
				> * {
					margin-top: 0;
				}
			}
		}
	}

}

@media @desktopQuery {
	.content-page.plain{
		hgroup h1,
		.subtitle,
		.preamble,
		.owc_content.columns{
			width: 500px;
		}

		.owc_content{
			&.columns{
				.css-columns(1);
			}

			&.block{
				&:first-child{
					width: 60%;
				}
				&:last-child{
					width: 40%;
				}
			}

		}
	}
}
// article/page
.article-header {

	margin: 0 30px;

	&.secondary {
		margin-top: 70px;
		padding-top: 20px;
		border-top: @grey-border;
		border-width: 1px 0;
	}

	hgroup {
		clear: both;
		text-align: center;
		margin-bottom: 20px;
	}

	.date {
		display: block;
		margin-top: 30px;
		@media @desktopQuery { margin-bottom: 40px; }
	}
	.date:after{
		display: block;
		content: '';
		width: 36px;
		height: 1px;
		background-color: #ccc;
		margin: 25px auto;
	}

	@media @mobileQuery {
		.breadcrumb, .share-module, .find-retailers-call-to-action{
			display: none;
		}

		hgroup { margin-top: 20px;  }
	}

	@media @desktopQuery {
		hgroup {
			padding: 20px 0 10px;
		}

		.breadcrumb {
			.font-upper(10px, 1px);
			float: left;
			color: #303030;
			width: 350px;

			a {
				display: inline-block;
			}

			a:after {
				margin-left: 5px;
				display: inline-block;
				content: '/';

			}

			a:last-child {
				color: #939598;

				&:after {
					content: '';
				}
			}
		}

		.find-retailers-call-to-action{
			float: right;
			margin-right: 1rem;
		}
		.share-module {
			position: relative;
			float: right;
			font-size: 1rem;
			cursor: default;

			ul {
				.arrowbox( up, 6px, #444, 80% );
				position: absolute;
				visibility: hidden;
				top: 25px;
				right: 0;
				padding: 8px;
				background: #444;
				color: #fff;
				opacity: 0;
				-webkit-transition: visibility 0s ease 1s, opacity .2s ease .8s;
				-moz-transition: visibility 0s ease 1s, opacity .2s ease .8s;
				transition: visibility 0s ease 1s, opacity .2s ease .8s;

				li {
					padding-top: 6px;

					&:first-child {
						padding: 2px 0 2px;
					}
				}
			}

			&:hover ul {
				visibility: visible;
				opacity: 1;
				-webkit-transition-delay: 0s;
				-moz-transition-delay: 0s;
				transition-delay: 0s;
			}

			.twitter-share-button { width: 90px !important; }
		}
	}
}


.article-content {
	margin: 0 60px 40px;
	@media @mobileQuery {
		margin: 0 30px 40px;
	}

	.attachment-featured-wide + .preamble {
		margin-top: 3rem;
	}

	a {
		text-decoration: underline;
	}

	p + h1,
	p + h2,
	p + h3,
	p + h4{
		margin-top: 3rem;
	}

	ul:not(.tab-menu),
	ol{
		font-size: 1.4rem;
		line-height: 1.5;
		color: rgb(82, 82, 82);
		padding-left: 0;
		li{
			padding-left: 20px;
		}
	}
	ol{
		padding-left: 20px;
		li{
			padding-left: 0;
			list-style-type: decimal;
		}
	}

	ul:not(.tab-menu) li:before{
		display: inline-block;
		width: 15px;
		margin-left: -15px;
		content: '-';
		color: #999;
	}
}

/* Main sections */
.main-sections {
	@sticky-height: 39px;

	.section-wrapper {
		position: relative;
		padding: @sticky-height 0 0;
		height: @sticky-height;
		overflow: hidden;
		.transition(height @animation-speed );

		&.open {
			height: auto;
			.transition(none);
		}
	}

	.section-sticky {
		position: absolute;
		top: 0px;
		left:0;
		right:0;
		height: @sticky-height;
		margin: 0;
		background-color: #000;
		border-top: 1px solid #222;
		color: #fff;
		text-align: center;
		z-index: 10;
		.font-upper(14px, 1px);
		.transition(background-color .2s);

		&:hover { background-color: #222; }

		a {
			display: block;
			padding: 14px;

			&:hover { text-decoration: none; }

			&:focus, .touch & {
				text-decoration: none;
				outline: none;
			}
		}

		&.fixed {
			position: fixed;
			z-index: 15;
			@media @desktopQuery {
				margin: 0 10px;
				-webkit-transform: translate3d(0, 0, 0);
			}
		}
	}

	.section-wrapper:first-child .section-sticky,
	.section-wrapper.open + .section-wrapper .section-sticky {
		border: none;
	}

	.main-content {
		position: relative;

		& > article,
		& > section {
			padding: 30px 0;
			background: #fff;

			.tag {
				@media @mobileQuery { .tag-element(12px); }
				@media @desktopQuery { .tag-element(18px); }
			}

		}
	}
	.cached-content { display: none; }

	@media @desktopQuery {
		// margin to leave room for featured news
		margin-bottom: 100px;
		.section-open & { margin-bottom: 0; }
	}

	.sticky-tab {
		bottom: 0;
		left: 50%;
		width: 80px;
		height: 0;
		margin-left: -40px;
		background: #000;
		.append-sprite-icon(0, 3, 24px, 16px, 27px, 10px);
		position: fixed;
		.transition(height .1s);
		z-index: 10;
		cursor: pointer;

		&.visible {
			height: 30px;
		}
	}
}



/* Watches */

// category list
.watches-categories {
	position: relative;

	.center {
		overflow: hidden;
		max-width: 1226px;
	}

	.watches-categories-list {
		overflow: hidden;
		margin: 0 auto;
		padding: 40px 0;

		li {
			.category-wrapper {
				position: relative;
				background: #fff;
				padding: 30px 15px 15px;
				text-align: center;
				min-height: 33rem;
			}

			h3 {
				font-size: 2.4rem;
			}

			.subtitle { font-size: 1.7rem; }

			img {
				display: block;
				margin: 10px auto;
			}

			a:hover { text-decoration: none; }

			.button {
				margin: 10px 0 7px;
			}

			.tag {
				.tag-element(12px);
			}
		}

		.amount {
			margin-top: 20px;
			display: block;
			text-transform: uppercase;
			font-size: 1.1rem;
		}

		// .section-links {
		// 	padding: 10px 0;

		// 	a {
		// 		display: inline-block;
		// 		font-size: 1.1rem;
		// 		letter-spacing: 1px;
		// 		margin-right: 10px;

		// 		&:hover {
		// 			text-decoration: underline;
		// 		}

		// 		&:last-child {
		// 			margin-right: 0;
		// 		}
		// 	}
		// }
	}

	@media @mobileQuery {
		padding: 0 15px;

		.watches-categories-list li {
			width: 100%;
			margin-bottom: 20px;
		}
	}

	@media @smallDesktopQuery {
		.watches-categories-list {
			li {
				h3 {
					font-size: 2rem;
				}

				.subtitle {
					font-size: 1.5rem;
				}
			}
		}
	}

	@media @desktopQuery {
		.watches-categories-list {
			table-layout: fixed;
			position: relative;
			.transition(left @animation-speed / 2);
			z-index: 1;

			li {
				float: left;
				padding: 0 5px 0;
				width: 245px;

				&:hover hgroup {
					color: #999;
				}
				&:hover .button {
					background: #f0f0f0;
				}
			}

			hgroup {
				min-height: 86px;
				margin-top: 15px;
			}

			.button {
				margin-top: 20px;
			}


		}
	}

	// when carousel arrows are displayed
	@media (max-width: 1284px) and @desktopQuery {
		.watches-categories-list {
			&:after, &:before {
				content: '';
				position: absolute;
				top: 0;
				width: 80px;
				height: 100%;
				pointer-events: none;
				z-index: 2;
			}

			&:after {
				left: 0;
				#gradient.horizontal(rgba(0,0,0,.7), rgba(0,0,0,0), rgba(0,0,0,0));
			}

			&:before {
				right: 0;
				#gradient.horizontal(rgba(0,0,0,0), rgba(0,0,0,.7), rgba(0,0,0,0));
			}
		}
	}

}


.slideshow-arrow {
	position: absolute;
	top: 45%;
	display: block;
	background-color: #fff;
	.size(38px, 60px);
	.sprite-image();
	background-position: -136px -74px;
	.transition(opacity @animation-speed / 2);
	cursor: pointer;
	z-index: 3;

	&.prev { left: 0; }
	&.next { right: 0; background-position: -86px -74px; }

	.first &.prev,
	.last &.next {
		opacity: .6;
	}
}


// single category and product page shared styles
.watches-page {

	.subtitle { font-size: 4rem; }
	h1 + .subtitle { font-size: 2rem; }

	@media @mobileQuery {
		margin: 20px 15px 0;
	}
	@media @desktopQuery {
		margin: 25px auto 0;

		.subtitle { font-size: 6rem; }
		h1 + .subtitle { font-size: 4rem; }
	}

	@media (max-width: 1010px) and (min-width: @mobileBreakpoint) {
		// special to leave the background visible
		margin: 25px 15px 0;
	}

	// category article
	.category-article-page{
		.subtitle{
			margin-top: 4rem;
			font-size: 2rem;

			@media @desktopQuery {
				font-size: 4rem;
			}
		}
	}
}

// foldable model/variation list
.watches-model-list {
	background: #e9e7e3;
	border: @grey-border;
	border-width: 1px 0;

	article {
		text-align: center;

		.product-name {
			margin: 10px 0;
			font-size: 1.1rem;
			letter-spacing: 1px;
			min-height: 26px;
		}
	}

	.show-all {
		padding-right: 27px;
		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 7px;
			right: 10px;
			border: solid transparent;
			border-bottom-color: #000;
			border-width: 0 4px 7px;

		}
	}

	&.closed {
		.show-all:after {
			border-top-color: #000;
			border-bottom-color: transparent;
			border-width: 7px 4px;
		}

		.list-row {
			display: none;
			&:first-of-type {  display: block; }
		}
	}

	@media @mobileQuery {
		article {
			padding: 25px 0 20px;
			border-top: @grey-border;
		}
		.list-row{
			&.animate {
				overflow: hidden;
				.transition(height @animation-speed);

				article { display: block; }
			}

			&:first-child article { border: none; }
		}

		&.closed {
			.list-row {
				display: none;
				&:first-of-type { display: block; }
			}
			article {
				display: none;
				&:first-child { display: block; }
			}
		}
	}
	@media @desktopQuery {
		.list-row {
			overflow: hidden;
			border-top: @grey-border;

			&:first-of-type {
				border: none;
			}
		}

		.row-content {
			// max-width: 800px;
			margin: 0 auto;
			padding: 0;
		}

		article {
			padding: 40px 0;
			width: 100%;
			border-right: @grey-border-dotted;

			&:nth-child(4) { border: none; }

			&:hover .button { background: #f0f0f0; }
		}

		.center-row {
			text-align: center;

			article {
				display: inline-block;
				float: none;

				&:first-child { border-left: @grey-border-dotted; }
			}
		}

		.two-items {
			text-align: center;
			article:first-child {
				margin-left: 24.9%; // odd rounding trick to center and align the borders...
				width: 25.2%;
				max-width: 201px;
				border-left: @grey-border-dotted;
			}
		}

		// hide show more if there is only one row on desktop
		.list-row:first-of-type:last-of-type + .show-all {
			display: none;
		}

		.no-carousel {
			text-align: center;

			article {
				display: inline-block;
				width: 24.75%;

				&:last-child {
					border: none;
				}
			}
		}
	}
}

// single product page
@media @desktopQuery {
	.image-fader, .product-details {
		float: left;
		width: 45%;
	}

	.product-details {
		margin-left: 5%;
		padding-left: 5%;
		border-left: 1px solid #dbdbdb;
		margin-bottom: 30px;
	}
}


.image-fader {
	position: relative;
	margin-bottom: 40px;
	cursor: none;

	img {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		.transition(opacity @animation-speed / 2 linear);
		width: 100%;

		&.current { opacity: 1; }
	}

	img:first-child {
		position: relative;
	}

	.magnifyer {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		margin-left: -90px;
		margin-top: -90px;
		.size(180px);
		border: 2px solid #ccc;
		.border-radius(180px);
		background: #fff no-repeat;
		.box-shadow(2px 2px 8px rgba(0,0,0,.2));
	}
}

.info-table {
	width: 100%;
	font-size: 1.4rem;
	margin-bottom: 20px;
	border-collapse: separate;

	tr {
		vertical-align: top;

		&:first-child td {
			padding-top: 0;
		}
	}

	td {
		padding: 7px 0;
		border-bottom: @grey-border-dotted;

		&[colspan] {
			font-weight: bold;
			padding: 20px 0;
		}
	}

	td:first-child:not([colspan]) {
		font-weight: bold;
		width: 35%;
		padding-right: 10px;

		&:after {
			content: ':';
		}
	}
}

.product-details {
	color: #525252;
}

.product-details .product-variations {
	padding-bottom: 20px;
	margin-bottom: 10px;
	border-bottom: @grey-border-dotted;


	strong { font-size: 1.4rem; }

	ul {
		overflow: hidden;
		margin: 10px 0;

		li {
			display: block;
			float: left;
			border: @grey-border;
			padding: 0;
			width: 22%;
			height: 0;
			margin-right: 4%;
			padding-bottom: 10%;
			overflow: hidden;

			&:last-child { margin-right: 0; }

			&:before { display: none; }
		}
	}


	.show-all {
		position: relative;
		clear: both;
		padding-right: 15px;
		font-size: 1.4rem;

		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 6px;
			right: 0;
			border: solid transparent;
			border-top-color: #000;
			border-width: 6px 4px 0;

		}
	}

}


.slideshow {
	overflow: hidden;
	visibility: hidden;
	position: relative;
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: @grey-border-dotted;
	text-align: center;

	h3 {
		margin-bottom: 15px;
	}

	&.no-captions { border-color: transparent; }

	.slides {
		overflow: hidden;
		position: relative;

		& > * {
			float:left;
			width:100%;
			position: relative;
		}
	}

	figcaption {
		max-width: 500px;
		margin: 20px auto 0;

		p {
			font-size: 1.2rem;
			margin-bottom: 0;
		}
	}
}

// concierge / buy bar
.product-concierge {
	clear: both;
	margin: 20px 0;
	background: #e9e7e3;
	border: @grey-border;
	border-width: 1px 0;
	.font-upper();


	.purchase-wrapper, .buy-button-form {
		display: inline-block;
	}

	@media @mobileQuery {
		padding: 0 20px 20px;
		text-align: center;

		.custom-select {
			display: block;
			width: 80%;
			margin: 30px auto 15px;
		}
		.title { .overlap-title(); }
		.phone-icon { margin-right: 10px; }
		.button { margin-top: 15px; }
	}

	@media @desktopQuery {
		padding: 0 60px;

		.concierge-wrapper {
			display: inline-block;
			padding: 12px 15px 12px 0;
			margin-right: 15px;
			border-right: 1px dotted #bbb;

			a { margin-left: 15px; display: inline-block; }

			.custom-select {
				margin-top: -3px;
			}
		}
	}
}


/* Glasshütte */
.glashutte-page {
}

.parallax-image {
	position: relative;

	img {
		width: 100%;
	}

	@media @mobileQuery {
		height: auto !important; /* important to override js-applied inline style */
		img {
			-webkit-transform: none !important;
			-moz-transform: none !important;
			transform: none !important;
		}
	}
	@media @desktopQuery {
		height: 300px;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
		}

		.touch & {
			height: auto;
			img { position: relative; }
		}
	}
}

/* Featured news */
.featured-news {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0 20px;
	overflow: hidden;

	.featured-news-content {
		position: relative;
		top: 0;
		visibility: visible;
		width: 100%;
		max-width: 500px;
		padding: 10px 10px 0;
		margin: 0 auto;
		background: #fff;
		overflow: hidden;
		-webkit-transition: -webkit-transform @animation-speed / 2 1s;
		-moz-transition: -moz-transform @animation-speed / 2 0;
		transition: transform @animation-speed / 2 1s;
		-webkit-transform: translateY(0%);
		-moz-transform: translateY(0%);
		transform: translateY(0%);

		.section-open & {
			-webkit-transform: translateY(100%);
			-moz-transform: translateY(100%);
			transform: translateY(100%);
			-webkit-transition-delay: 0s;
			-moz-transition-delay: 0s;
			transition-delay: 0s;
		}

		.ie .section-open & { display: none; }
	}

	.featured-news-img {
		float: left;
		width: 45%;
		padding-right: 15px;
	}

	.featured-news-img + .featured-news-text {
		width: 55%;
		float: left;

		.date {
			display: block;
			margin-top: 10px;
		}
	}

	.title {
		margin: 5px 0 10px;
		font-family: Tutima;
		font-size: 1.8rem;
	}

	.section-open & {
		visibility: hidden;
	}

	@media @mobileQuery {
		.featured-news-content { padding-bottom: 10px; }
	}
}

// card slideshow
.card-slider {
	position: relative;

	.center {
		overflow: hidden;
		max-width: 980px;
		padding: 40px 0;

		ul {
			overflow: hidden;
			margin: 0 auto;

			li {
				.card-wrapper {
					position: relative;
					background: #fff;
					padding: 15px;
					text-align: center;
				}
			}
		}
	}


	@media @mobileQuery {
		padding: 0 15px;

		.center li {
			width: 100%;
			margin-bottom: 20px;

			&:nth-child(1n+5) { display: none; }
		}
	}

	@media @desktopQuery {
		padding: 0 30px 50px;

		.center {
			padding-bottom: 50px;

			ul {
				display: table;
				table-layout: fixed;
				position: relative;
				left: 0;
				top: 0;
				.transition(left @animation-speed / 2);

				li {
					display: table-cell;
					padding: 0 10px 0;
					max-width: 245px;
					vertical-align: top;
				}
			}
		}
	}
}

/* Sponsorship and activities */
.featured-activities {
	margin: 0 auto;

	.card-wrapper {
		text-align: center;

		img {
			margin-bottom: 10px;
		}

		h4 {
			margin-bottom: 10px;
		}
	}

	@media @mobileQuery {

	}

	@media @desktopQuery {
		.card-wrapper {
			min-height: 340px;
			h4 { min-height: 120px; }
		}
	}
}

/* Retailer */
.retailer-page{
	.article-header{
		.clearfix();
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border-bottom: @grey-border;

		hgroup{
			text-align: left;
		}
		h1 {
			float: left;
		}



		form {
			display: block;
			width: 33%;
			float: left;

			label{
				.font-upper();
				display: block;
			}
		}
	}
	.article-content{
		margin: 0 30px 40px;
		clear: both;
		h2{
			margin-top: 4rem;
			margin-bottom: 2rem;
		}
		.filter {
			margin-bottom: 4rem;
			> form {
				margin-bottom: 1em;
			}
			.location-filter{
				display: inline-block;
				margin-right: 4rem;
			}
			.location-filter-heading{
				margin-bottom: 1rem;
			}
		}
		.start-image{
			text-align: center;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.preamble{
		text-align: left;
		margin: 0;
	}

}

/* Slider switcher buttons */
.article-slider-buttons {
	position: relative;
	top: 1px;
	margin-left: 60px;

	.button {
		display: inline-block;
		margin: 0;
		padding: 10px 40px;
		border-bottom: 1px solid #e9e7e3;
		font-size: 16px;
		background-color: #e9e7e3;

		&.disabled {
			background-color: #fff;
		}
	}

	@media @mobileQuery {
		margin-left: 30px;
		.button {
			font-size: 12px;
		}
	}
}
/* Watches secitons */
.section-content {
	transition: opacity .5s 0s ease-out;
	opacity: 1;
	height: auto;

	&.section-content-hidden {
		opacity: 0;
		height: 0;
		overflow: hidden;
	}
}


.retailer-single{
	border-top: @grey-border-dotted;
	padding-top: 2rem;
}

@media @desktopQuery {
	.retailer-page{
		.retailer-single:nth-child(odd) {
			margin-right: 5%;
		}
		.retailer-single{
			.clearfix();
			width: 47%;
			display: inline-block;
			.blocks{
				.clearfix();
				float: left;
				width: 75%;
				.block{
					float: left;
				}
			}
			img{
				float: right;
				width: 25%;
			}
		}

		.article-content{
			.start-image{
				text-align: center;
				img {
					height: 300px;
					width: auto;
				}
			}
		}
	}

}

@media @mobileQuery {
	.retailer-page{
		.article-header{
			hgroup{
				h1 {
					float: none;
				}
			}
			.filter {
				float: none;
				width: 100%;
				margin-top: 2rem;
			}
			form {
				width: 100%;
				margin-bottom: 2rem;
				display: block;
				float: none;
			}
		}

	}
}


/* Content page */
.page-template-default,
.page-template-plain-php,
.error404,
.retailer {
	.site-header {
		background: #000;
	}

	.main-content {
		background: #FFF;
		padding: 60px 0;
	}
}

.content-page.plain{
	.article-header{
		hgroup{
			text-align: left;
		}
	}
	.article-content{
		margin: 0 30px 40px;
	}
	.preamble{
		text-align: left;
		margin: 0;
	}

}

/* Single Page */
.single-page{
	.tag();
	margin: 40px auto 0;

	@media @mobileQuery {
		margin: 20px 15px 0;

	}
	@media @desktopQuery {
		margin: 25px auto 0;
	}
}

.index-page{
	.article-content{
		margin: 0 80px 40px;
	}
	.owc_content{
		height: 233px;
		border-bottom: @grey-border;

		&:first-child { margin-top: 0; }

		.block:first-child{
			padding-right: 0;
			border-right: none;
		}
		.block:last-child{
			min-height: 100%;
		}
		.date {
			display: block;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		h3{
			margin-bottom: 1rem;

			a {
				text-decoration: none;

				&:hover { text-decoration: underline; }
			}
		}
		.button{
			text-decoration: none;
			letter-spacing: 1px;
			position: absolute;
			bottom: -1px;
		}
	}
}
@media @mobileQuery {
	.index-page{
		.article-content{
			margin: 0 40px 40px;
		}
		.owc_content{
			height: auto;
			.block:last-child{
				padding-bottom: 25px;
			}
		}
	}
}
.children .child-page{
	border-bottom: @grey-border;
	padding-top: 3px;

	.more-link{
		.button();
		text-decoration: none;
		bottom: -1px;
		left: 2rem;
		position: absolute !important;
		letter-spacing: 1px;
		padding-left: 10px;

		@media @mobileQuery {
			bottom: 20px;
		}
	}
	&:not(.child-page-open){
		.more-link{
			.append-sprite-icon(2, 3, 13px, 9px, 4px, 7px);
		}
	}
	&.child-page-open{
		.more-link{
			.append-sprite-icon(3, 3, 13px, 9px, 4px, 7px);
		}
	}

	.full-content{
		display: none;
		> p:first-child{
			display: none;
		}
		> p:nth-child(2){
			margin-top: 0;
		}
		> p:last-child{
			margin: 0;
			padding-bottom: 40px;
		}
	}




	&.hover{
		background-color: rgb(247, 247, 247);

	}
	&:first-child{
		border-top: @grey-border;
	}
	&:not(:first-child){
		margin-top: 1rem;
	}

	.block{
		> h3{
			//margin-top: 3rem;
		}
		> img { display: block; }

		&:first-child {
			padding: 3rem 2rem 2rem 2rem;
			min-height: 226px;
			border-right: none;

			@media @mobileQuery { padding-bottom: 4rem; }
		}
	}
}

/* Instruction Booklets */

.instructions-list {
	padding: 0 1.5%;
	overflow: hidden;
	li {
		float: left;
		width: 47%;
		margin: 0 1.5% 8%;
		text-align: center;


		@media @desktopQuery {
			width: 23%;
			margin: 0 1% 5%;
		}

		h3 {
			font-size: 2.2rem;
			margin: 5% 0 9%;
		}

		img {
			width: 100%;
		}

		a {
			position: relative;
			.button;
			padding: 5px 30px 4px 8px;

			&:before {
				display: block;
				position: absolute;
				right: 8px;
				top: 7px;
				content: '';
				.size(0);
				border-style: solid;
				border-width: 6px 4px;
				border-color: #3a3a3a transparent transparent transparent;
			}

			&:after {
				position: absolute;
				display: block;
				content: '';
				right: 8px;
				top: 14px;
				.size(8px, 1px);
				background: #3a3a3a;
			}

		}
	}
}


/* Purchase / Checkout */
.checkout {

	.checkout-page {
		padding: 0 30px;
	}

	.empty-cart {
		margin: 40px 0;
	}

	.article-header {
		.clearfix();
		margin: 0;
	}

	.main-content {
		background: #fff;
		padding: 20px 0;
		.clearfix();
	}

	.checkout-cart {
		section {
			margin: 20px 0 40px;
		}

		h4 {
			border-bottom: @grey-border;
			margin-bottom: 20px;
			padding-bottom: 15px;
		}
	}

	.checkout-products {
		dl {
			font-size: 12px;
			overflow: hidden;
		}

		dd, dt {
			width: 50%;
			float: left;
			margin: 0;
		}

		dt {
			clear: both;
			padding: 0 5px 8px 0;
		}

		.price, .total {
			font-weight: bold;
		}

		.total dd {
			font-size: 14px;
		}
	}

	.checkout-product-list {
		li {
			position: relative;
			border-bottom: @grey-border;
			padding-bottom: 20px;
			margin-bottom: 20px;
			overflow: hidden;
		}

		button {
			border: none;
			background: rgba(0, 0, 0, 0);

			&:focus {
				outline: none;
			}
		}

		.product-image {
			display: block;
			text-align: center;
			margin-bottom: 20px;
		}

		.product-title {
			display: block;
			font-family: TutimaLight;
			letter-spacing: -0.03em;
			font-size: 40px;
			font-weight: normal;
			line-height: 1;
			text-decoration: none;

			span {
				display: block;
				font-size: 30px;
			}
		}


		.del-item {
			padding: 0;
			.font-upper(10);

			span {
				display: inline-block;
				background: #000;
				color: #fff;
				.size(15px);
				font-family: sans-serif;
				font-size: 11px;
				padding-top: 1px;
			}
		}
	}

	.checkout-payment {
		li {
			display: inline-block;
			font-size: 14px;
			margin-right: 30px;

			&.dibs_flexwin_v1,
			&.adyen_other_v1,
			&.adyen_v1{
				img{
					width: 143px;
				}
			}

			img {
				margin: 10px 0 10px 5px;
				vertical-align: middle;
			}

			a {
				font-size: 11px;
			}
		}
	}

	.checkout-buyer-details {
		.klarna-input {
			border-bottom: 1px solid #CCC;
			overflow: hidden;
			margin-bottom: 20px;
			padding-bottom: 10px;

			a {
				float: right;
			}
		}

		label {
			display: block;
			margin-bottom: 10px;

			.label {
				display: block;
				margin-bottom: 5px;
				.font-upper(1rem);
			}

			input[type=text], .custom-select {
				display: block;
				width: 100%;
			}
		}

		.checkout_standard_checkbox {
			cursor: pointer;
		}

		.accept {
			clear: both;
			padding-top: 20px;

			label {
				width: 100%;
				float: none;
			}

			a {
				text-decoration: underline;
			}
		}
	}


	.billing-info,
	.shipping-info{
		table{
			font-size: 12px;
			margin-bottom: 24px;
			width: 100%;
			td{
				width: 50%;
			}
		}
	}
	.alternative_shipping{
		.clearfix();
		clear: left;
		padding-top: 20px;
	}


	// errors
	.checkout-errors {
		border: 1px solid #C00;
		padding: 10px;
		margin: 10px 0 20px;
	}
	.error-text {
		display: none;
	}
	.error {
		input {
			border-color: #A66;
		}

		.error-text {
			display: block;
			color: #A66;
			font-size: 11px;
			margin-top: 4px;
		}
	}

	.checkout-sidebar {
		border-top: @grey-border;
		padding-top: 30px;

		& > * {
			margin-bottom: 40px;

			h5 {
				line-height: 1.2;
				margin-bottom: 10px;
				font-size: 2.4rem;
			}
		}

		.concierge-wrapper  {
			.title {
				font-size: 1.2rem;
			}
			a, .custom-select {
				display: block;
				margin-top: 10px;
				.font-upper(10px);
				letter-spacing: 0;
			}
		}

		nav {
			a {
				display: block;
				.font-tutima(1.4rem);
			}
		}
	}

	@media @desktopQuery {
		.checkout-cart {
			float: left;
			width: 74%;
			padding-right: 8%;
			margin-right: 3%;
			border-right: @grey-border;
		}

		.checkout-product-list {
			.product-image {
				width: 35%;
				float: left;
				margin: 0;
				padding-right: 20px;
			}

			.del-item {
				position: absolute;
				bottom: 20px;
				right: 0;
			}
		}

		.checkout-products .total {
			dt {
				width: 67%;
			}

			dd {
				width: 30%;
			}
		}

		.checkout-buyer-details {
			.klarna-input a {
				float: none;
			}

			label {
				float: left;
				width: 40%;
				margin-right: 10%;

				&:nth-of-type(2n+1) {
					clear: left;
				}
			}

			.klarna_continue {
				margin-top: 17px;
			}
		}

		.checkout-sidebar {
			float: left;
			width: 23%;
			border: none;
			padding: 0;
		}
	}
}

/* Checkout redirect, failed payment */
.checkout-related-page {
	padding: 30px 0 50px;
	text-align: center;

	&.payment-page{
		.loading-indicator{
			margin: 20px 0;
			width: 60px;
			height: auto;
		}
	}
}

/* Sidebar*/
.site-sidebar{
	.widgettitle {
		line-height: 1.2;
		margin-bottom: 10px;
		font-size: 2.4rem;
	}
	.textwidget{
		font-size: 1.2rem;
		a {
			text-decoration: underline;
		}
	}

}



/* Ninja Forms */
.ninja-forms-response-msg,
.ninja-forms-form > p:first-child,
.ninja-forms-form br,
.ninja-forms-field-error,
#ninja_forms_required_items,
.ninja-forms-field + p,
.ninja-forms-field-error + p{
	display: none;
}
.ninja-forms-success-msg{
	display: block !important;
}
.ninja-forms-form {
	position: relative;
	width: 100%;
	margin-left: -10px;

	.ninja-forms-error {
		color: #f00;
	}
	.ninja-forms-req-symbol{
		color: inherit !important;
	}


	.field-wrap{

		&.list-radio-wrap,
		&.textarea-wrap{
			clear: both;
		}

		position: relative;
		width: 100%;
		margin-bottom: 3rem;
		padding-left: 10px;

		&.text-wrap,
		&.list-dropdown-wrap,{
			display: inline-block;
			width: 50%;
			float: left;

		}
		select{
			width: 100%;
		}

		textarea,
		input[type=text] {
			position: relative;
			width: 100%;
			padding: 0 5px;
			.clear-apperance();
			border: @grey-border;
			font-size: 1.2rem;
			color: #333;
		}

		input[type=text]{
			height: 24px;
		}
		input[type=submit]{
			.button();
			letter-spacing: 1px;
		}

		textarea{
			height: 152px;
			padding:  5px;
		}

		> label{
			display: block;
			.font-upper(11px);
			font-weight: normal;
			letter-spacing: 1px;
			margin-bottom: 0.5rem;
			margin-top: rem;
		}

		ul li{
			padding-left: 0;
			input[type=radio]{
				margin-right: 7px;
			}

		}
		ul li:before{
			display: none;
		}
	}
}



/* Footer */
.site-footer {
	display: none;

	@media @desktopQuery {
		// display footer only on desktop when a section is open
		.section-open & {
			display: block;
			.meta-menu { height: auto; border-top: 1px solid #eee; }
		}

		// reverse content
		.meta-menu {
			.logo-contact {
				float: right;
			}

			.logo, .contact {
				float: right;
			}

			.contact {
				margin: 50px 40px 20px 0;
			}
		}
	}
}

/**
 * Owl Carousel v2.1.6
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
	//display: none;
	display: block;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */

	position: relative;
	z-index: 1;
	padding: 0 80px;
	overflow: hidden;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	// overflow: hidden;
	/* fix for flashing background */

	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	//-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
	display: block;
	width: 100%;
	//-webkit-transform-style: preserve-3d;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid #ddd;
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
	display: none;
}
.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}
/* No Js */

.no-js .owl-carousel {
	display: block;
}
/*
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	animation-name: fadeOut;
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	transition: height 500ms ease-in-out;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d;
}
/*
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("owl.video.play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}
.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}
.owl-next, .owl-prev {
	transition: opacity 1s;
	opacity: 1;

	&:hover {
		background-color: #f1f1f1;
	}

	&.disabled {
		opacity: 0;
	}
}
.owl-next {
	&:extend(.slideshow-arrow);
	&:extend(.slideshow-arrow.next);
	color: transparent;
}
.owl-prev {
	&:extend(.slideshow-arrow);
	&:extend(.slideshow-arrow.prev);
	color: transparent;
}

// home overwrites
.watches-categories .owl-carousel {
	padding-left: 0;
	padding-right: 0;

	@media @mobileQuery {
		display: block;
	}
}
